<template>
	<div id="course">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">课程时段</a></el-breadcrumb-item>
			</el-breadcrumb>
			<!-- <div class="head u-f-item">
				<span class="span">选择条件</span>
				<el-select size="small" v-model="condition" placeholder="请选择" style="width: 150px;" @change="schoolChange">
					<el-option v-for="item in conditionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div> -->
			<div class="content">
				<div class="u-f">
					<div class="u-f3 left">
						<div class="add u-f-justify el-icon-plus" @click="addtime = true"></div>
						<div class="time_list">
							<!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox> -->
							<div
								class="time_item u-f-item u-f-jsb"
								v-for="(item, index) in timeList"
								:key="index"
								@mouseover="item.isShow = true"
								@mouseleave="item.isShow = false"
								:class="index == timeindex ? 'active' : '' "
								@click="timeTap(index)"
							>
								<span>{{ item.name }}{{ item.is_default == 1 ? '(默认)' : '' }}</span>
								<div class="u-f-item btn" v-if="item.isShow">
									<i class="el-icon-edit-outline u-f-justify" @click.prevent @click.stop="editShow(item)"></i>
									<i class="el-icon-delete u-f-justify" @click.prevent @click.stop="delSubject(item.id)"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="u-f10 right">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ num }}条数据
							</div>
							<div class="u-f-item">
								<!-- <el-input placeholder="请输入课程时段名称搜索" v-model="sousuo" size="small" style="margin-right: 10px;">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<el-button size="small" type="primary" @click="search">搜索</el-button> -->
								<el-button size="small" @click="openDialog(1)">添加课程时段</el-button>
								<!-- <el-button size="small">批量更新</el-button>
								<el-button size="small">批量录入</el-button>
								<el-button size="small" type="primary">导出</el-button>
								<el-button size="small" icon="el-icon-s-operation">排序</el-button> -->
							</div>
						</div>
						<el-table
							:data="courseList"
							height="100%"
							ref="multipleTable"
							tooltip-effect="dark"
							@filter-change="filterChange"
							style="width: 100%;border: 1px solid #EEEEEE;"
						>
							<el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
							<el-table-column
								align="center"
								prop="name"
								label="时段"
								column-key="name"
								:filter-method="filterHandler"
								:filters="filters"
								:filter-multiple="false"
							></el-table-column>
							<el-table-column  align="center" prop="node" label="课程时段"></el-table-column>
							<el-table-column  align="center" prop="start" label="开始时间"></el-table-column>
							<el-table-column  align="center" prop="end" label="结束时间"></el-table-column>
							<el-table-column  align="center" label="时段属性">
								<template slot-scope="scope">
									<span v-if="scope.row.type == 1">课程</span>
									<span v-if="scope.row.type == 2">非课程</span>
								</template>
							</el-table-column>
							<el-table-column align="center" label="操作" width="100">
								<template slot-scope="scope">
									<el-button @click="openDialog(2,scope.row)" type="text" size="small">编辑</el-button>
									<el-button @click="delcourse(scope.row.id,scope.row.time_id)" type="text" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>

			<el-dialog title="添加时段" :visible.sync="addtime" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							时段名称
						</div>
						<el-input v-model="timeName" placeholder="请输入时段名称" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addtime = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="add">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="编辑时段" :visible.sync="edittime" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							时段名称
						</div>
						<el-input v-model="edittimeName" placeholder="请输入时段名称" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							是否默认
						</div>
						<el-switch v-model="is_default" active-value="1" inactive-value="2" active-color="#2D8CF0" inactive-color="#DEE1E8"></el-switch>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="edittime = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="edit">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 添加课程时段 -->
			<el-dialog :title="dialogTitle" :visible.sync="addcourse" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							时段
						</div>
						<el-select v-model="time_id" placeholder="请选择时段" style="width: 85%;">
							<el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							名称
						</div>
						<el-input v-model="courseName" placeholder="请输入名称" style="width: 85%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							时间
						</div>
						<div class="u-f-item" style="width: 85%;">
							<el-time-select
								placeholder="起始时间"
								v-model="startTime"
								:picker-options="{
									start: '07:00',
									step: '00:01',
									end: '18:30'
								}"
								style="width: 45%;"
							></el-time-select>
							<span style="margin: 0 15px;">-</span>
							<el-time-select
								placeholder="结束时间"
								v-model="endTime"
								:picker-options="{
									start: '07:00',
									step: '00:01',
									end: '18:30',
									minTime: startTime
								}"
								style="width: 45%;"
							></el-time-select>
						</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							属性
						</div>
						<el-radio-group v-model="type">
							<el-radio-button label="1">课程</el-radio-button>
							<el-radio-button label="2">非课程</el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addcourse = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="saveAddCourse">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			addcourse: false,
			sousuo: '',
			condition: '',
			conditionList: [],
			timeList: [],
			courseList: [],
			filters: [],
			addtime: false,
			timeName: '',
			timeId: '',
			edittimeName: '',
			edittime: false,
			is_default: 2,
			selectList: [],
			checkAll: [],
			isIndeterminate: true,
			courseName: '',
			time_id: '',
			type: 1,
			startTime: '',
			endTime: '',
			dialogTitle:'添加课程时段',
			dialogType:1,
			courseId:'',
			selected:0,
			timeindex:0,
			num:0
		};
	},
	mounted() {
		this.schoolCampusIndex();
		this.getcourseTimeList();
	},
	methods: {
		timeTap(index){
			this.timeindex = index;
			this.getcourseList()
		},
		search(){
			if(this.sousuo){
				this.getcourseList()
			}
		},
		schoolChange(e){
			window.localStorage.setItem("campusid",e);
			this.condition = e;
		},
		handleCheckAllChange(val) {},
		handleClose(done) {
			done();
			this.initialize()
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] === value;
		},
		filterChange(filters) {
			if (filters.time_name.length === 0) {
				//重置你自己的逻辑
			} else {
				//多选和单选
				this.selectList = this.courseList.filter(item => {
					//得到筛选后的数组
					for (let i = 0; i < filters.time_name.length; i++) {
						if (item.time_name === filters.time_name[i]) {
							return true;
							break;
						}
					}
				});
			}
		},
		handleSelectionChange(val) {
			console.log(val)
			this.selected = val.length;
		},
		// 校区列表
		schoolCampusIndex() {
			this.$api.setting.schoolCampusIndex().then(res => {
				if (res.data.code == 1) {
					this.conditionList = res.data.data.rows;
					this.condition = this.conditionList[0].id;
				}
			});
		},
		// 获取课程时段
		getcourseTimeList() {
			this.$api.setting.courseTimeList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.rows;
					for (let i in data) {
						data[i].isShow = false;
					}
					this.timeList = data;
					let arr = [];
					for (let i in data) {
						arr.push({
							text: data[i].name,
							value: data[i].name
						});
					}
					this.filters = arr;
					this.getcourseList();
				}
			});
		},
		getcourseList() {
			let data = {filter:JSON.stringify({time_id:this.timeList[this.timeindex].id})}
			if(this.sousuo){
				data.name = this.sousuo;
			}
			this.$api.setting.getCurriculumTime(data).then(res => {
				if (res.data.code == 1) {
					this.courseList = res.data.data.rows;
					this.num = res.data.data.total;
				}
			});
		},
		// 添加时段
		add() {
			if (this.timeName == '' || !this.timeName) {
				return this.$message.error('请输入名称');
			}
			this.$api.setting
				.addCourseTime({
					name: this.timeName
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.getcourseTimeList();
						this.addtime = false;
						this.timeName = '';
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 编辑时段
		editShow(item) {
			this.edittimeName = item.name;
			this.timeId = item.id;
			this.edittime = true;
			this.is_default = item.is_default;
		},
		edit() {
			if (this.edittimeName == '' || !this.edittimeName) {
				return this.$message.error('请输入名称');
			}
			this.$api.setting
				.editCourseTime({
					id: this.timeId,
					name: this.edittimeName,
					is_default: this.is_default
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('编辑成功');
						this.getcourseTimeList();
						this.edittime = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 删除时段
		delSubject(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delCourseTime({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getcourseTimeList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		// 添加课程时段
		saveAddCourse() {
			if (this.time_id == '' || !this.time_id) {
				return this.$message.error('请选择时段');
			}
			if (this.courseName == '' || !this.courseName) {
				return this.$message.error('请输入名称');
			}
			if (this.startTime == '' || !this.startTime) {
				return this.$message.error('请选择开始时间');
			}
			if (this.endTime == '' || !this.endTime) {
				return this.$message.error('请选择结束时间');
			}
			let data = {
				time_id:this.time_id,
				node:this.courseName,
				start:this.startTime,
				end:this.endTime,
				type:this.type
			}
			if(this.dialogType==1){
				this.$api.setting.addCurriculumTime(data).then(res=>{
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.getcourseList();
						this.addcourse = false;
						this.initialize()
					} else {
						this.$message.error(res.data.msg);
					}
				})
			}else if(this.dialogType==2){
				data.id = this.courseId;
				this.$api.setting.editCurriculumTime(data).then(res=>{
					if (res.data.code == 1) {
						this.$message.success('编辑成功');
						this.getcourseList();
						this.addcourse = false;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			}
			
		},
		// 打开弹窗
		openDialog(type,item){
			this.dialogType = type;
			if(type==2){
				this.time_id = item.time_id;
				this.courseName = item.node;
				this.startTime = item.start;
				this.endTime = item.end;
				this.type = item.type;
				this.courseId = item.id;
			}
			this.addcourse = true;
		},
		// 初始化数据
		initialize(){
			this.time_id = '';
			this.courseName = '';
			this.startTime = '';
			this.endTime = '';
			this.type = 1;
			this.timeName = '';
		},
		// 删除课程时段
		delcourse(id,time_id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delCurriculumTime({
						id: id,
						time_id:time_id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getcourseList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		}
	}
};
</script>

<style lang="scss">
#course {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 85vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 85vh;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.time_list {
				padding: 25px;
				.time_item {
					margin-top: 15px;
					padding: 0 12px 0 25px;
					height: 40px;
					line-height: 40px;
					background-color: #f8f8f8;
					font-size: 0.625vw;
					.btn {
						i {
							background-color: #ffffff;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.time_item:hover {
					span {
						color: #007aff;
					}
				}
				.active {
					background-color: #e4f1ff;
					color: #007aff;
				}
			}
		}
		.right {
			height: 75vh;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
